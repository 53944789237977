export const sections = [
    {
        title: "Home",
        url: "/",
        exact: true,
    },
    {
        title: "Lenape Land",
        url: "/lenape-land",
    },
    {
        title: "Morningside Heights",
        url: "/morningside-heights",
    },
    {
        title: "Present Day",
        url: "/present-day",
    }
];